import React from 'react'
import {Link} from 'react-router-dom'

function Video({video}) {
  return(
    <div className='borded border-border p-1 hover:scale-95 transition relative rounded overflow-hidden'>
        {/* {video.map((video, index) => ( */}
        {/* <Link to={`/video/${video?.title}`} className='w-full'> */}
        {/* <Link to={video.download_url} className='w-full'> */}
        {/* <Link to={`/watch/${video.title}`} className='w-full'> */}
        <Link to={video.download_url} target="_blank" rel="noopener noreferrer" className='w-full'>
          <img 
            src={video.single_img}
            // src={video.download_url}
            alt={video.title}
            className='w-full h-64 object-cover'/>
        </Link>
        <div className='absolute flex-btn gap-2 bottom-0 right-0 left-0 bg-main bg-opacity-60 text-white px-4 py-3'>
            <h3 className='font-semibold truncate'>{video.title}</h3>
        </div>
    </div>
    
  )
}

export default Video




// import React from 'react'
// import {Link} from 'react-router-dom'

// function Video({video}) {
//   return (
//     <div className='borded border-border p-1 hover:scale-95 transition relative rounded overflow-hidden'>
//         <Link to={`/video/${video?.name}`} className='w-full'>
//             <img 
//                 src={`/images/thumbnail/${video?.image}`} 
//                 alt={video?.name} 
//                 className='w-full h-64 object-cover'
//             />
//         </Link>
//         <div className='absolute flex-btn gap-2 bottom-0 right-0 left-0 bg-main bg-opacity-60 text-white px-4 py-3'>
//             <h3 className='font-semibold truncate'>{video?.name}</h3>

//         </div>
//     </div>
//   )
// }

// export default Video