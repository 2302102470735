import React,{useState} from 'react';
import Layout from'../Layout/Layout';
import Filters from '../Components/Filters';
import Video from '../Components/Video';
import {Files} from '../Data/VideosData';
// import { CgSpinner } from 'react-icons/cg';

function VideosPage() {
  const maxPage = 10
  const [page,setPage] = useState(maxPage)
  const HandleLoadingMore = () => {
    setPage(page + maxPage)
  }
    
  return (
    <Layout>
        <div className='min-height-screen container mx-auto px-2 my-6'>
            <Filters/>
            <p className='text-lg font-medium my-6'>
              Total <span className='font-bold text-subMain'>{Files?.length}</span>{"  "} 
              Items Found
            </p>
            <div className='grid sm:mt-10 mt-6 xl:grid-cols-4 2xl:grid-cols-5 lg:grid-cols-3 sm:grid-cols-2 gap-6'>
              {
                Files.slice(0,page)?.map((video,index) => (
                  <Video key={index} video={video}/>
                ))
              }
            </div>
            {/* Loading More */}
            <div className='w-full flex-colo md:my-20 my-10'>
              <button onClick={HandleLoadingMore} className='flex-fow gap-3 text-white px-8 rounded font-semibold border-2 border-subMain'>
                View More 
                {/* <CgSpinner className='animate-spin'/> */}
              </button>
            </div>
        </div>
    </Layout>
    
  )
}

export default VideosPage