import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AboutUs from './Screens/AboutUs';
import HomeScreen from './Screens/HomeScreen';
import ContactUs from './Screens/ContactUs';
import VideosPage from './Screens/Videos';
import NotFound from './Screens/NotFound';
import SingleVideo from './Screens/SingleVideo';
import WatchPage from './Screens/WatchPage';
import Login from './Screens/Login';


// import TestingPopular from './Components/Home/TestingPopular';

function App() {
 
  
  return (
    <Routes>
      <Route path='/' element={<HomeScreen />} />
      <Route path='/about-us' element={<AboutUs />} />
      <Route path='/contact-us' element={<ContactUs />} />
      <Route path='/login' element={<Login />} />
      <Route path='/videos' element={<VideosPage />} />
      <Route path='/video/:id' element={<SingleVideo />} />
      <Route path='/watch/:id' element={<WatchPage />} />
      <Route path='*' element={<NotFound />} />
      {/* <Route path='/testing' element={<TestingPopular />} /> */}
    </Routes>

  );
}

export default App 