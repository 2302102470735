import React from 'react'

function Head({title}) {
  return (
    <div className='w-full bg-deepGrey lg:h-64 relative overflow-hiddenrounded-md'>
        <img 
            src='/images/head.jpg'
            alt='aboutus'
            className='w-full h-full object-cover' 
        />
        <div className='absolute lg:top-24 top-16 w-full flex-colo'>
            <h1 className='text-2xl lg:text-h1 text-hite text-center font-bold'>
                {title && title}
            </h1>
        </div>        
    </div>
  )
}

export default Head