// import React, { useState } from 'react'
// import { Link, useParams } from 'react-router-dom'
import Layout from '../Layout/Layout'
import React from 'react'
import { Link } from 'react-router-dom'
// import {Files} from '../Data/VideosData';
import { BiArrowBack } from 'react-icons/bi'
// import { FaPlay } from 'react-icons/fa';
// import { FaCloudDownloadAlt, FaHeart, FaPlay } from 'react-icons/fa';

function WatchPage() {
    // let{id} = useParams()
    // const video = videobkp.find((video) => {video.title} === id)
    // const [play, setPlay] = useState(false)
return (
    <Layout>
        <div className='container mx-auto bg-dry p-6 mb-12'>
            <div className='flex-btn flex-wrap mb-6 bg-main rounded border border-gray-800 p-6'>
            {/* Drop Link here */}
            <Link to={`../`} className='md:text-xl text-sm flex gap-3 items-center font-bold text-dryGray' >
                <BiArrowBack /> 
                {/* {video.title} */}
            </Link>
                <div className='flex-btn sm:w-auto w-full gap-5'>

                </div>
            </div>
            {/* Watch Videos */}
            {
                <iframe 
                className=' w-full h-screen rounded'
                scrolling="no"
                width="560"
                height="315"
                src="https://doods.pro/e/71ch5dhsul2f"
                title="Embedded Video"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            }

        </div>
    </Layout>
)


}

export default WatchPage


// function WatchPage({video}) {
// //   let{id} = useParams()
// //   const video = Files.find((video) => video.name === id);
//   const [play, setPlay] = useState(false)
//   return <Layout>
//     <div className='container mx-auto bg-dry p-6 mb-12'>
//         <div className='flex-btn flex-wrap mb-6 bg-main rounded border border-gray-800 p-6'>
//              {/* <Link to={`../`} className='md:text-xl text-sm flex gap-3 items-center font-bold text-dryGray' >*/}
//                  {/* <BiArrowBack /> {video.title} */}
//              {/* </Link> */}
//             <div className='flex-btn sm:w-auto w-full gap-5'>
//                  {/* <button className='bg-white hover:text-subMain transitions bg-opacity-30 text-white rounded px-4 py-3 text-sm'>
//                      <FaHeart/>
//                  </button>
//                  <button className='bg-subMain flex-rows gap-4 hover:text-main transitions text-white rounded px-6 py-3 text-sm'>
//                      <FaCloudDownloadAlt/> Download
//                  </button> */}
//             </div>
//         </div>

//         {/* Watch Videos */}
//         {
//             play ? (
//                 <video controls autoPlay={play} className='w-full h-screen rounded'>
//                     {/* Change this video.mp4 with link from doodstreams */}
//                     <source 
//                         //  src='/images/videos/${video.video}' 
//                         // src='/images/videos/Army of the Dead - Official Teaser - Netflix.mp4'
//                         src={video.download_url}
//                         type='video/mp4' 
//                         title={video?.title} />
//                 </video>
//             ):(
//                 <div className='w-full h-screen rounded-lg overflow-hidden relative'>
//                     <div className='absolute top-0 left-0 bottom-0 right-0 bg-main bg-opacity-30 flex-colo '>
//                         <button onClick={() => setPlay(true)} className='bg-white text-subMain flex-colo border border-subMain rounded-full w-20 h-20 font-medium text-xl'>
//                             <FaPlay/>
//                         </button>
//                     </div>
//                     <img 
//                     src={
//                         // 3:32:21 / 6:00:51
//                         video?.image 
//                             ? `/image/videos/${video.image}`
//                             :"images/user.png"
//                     } 
//                     alt={video?.name} 
//                     className='w-full h-full object-cover rounded-lg' 
//                     />
//                 </div>
//             )
//         }
//     </div>


        
//   </Layout>
  
// }

// export default WatchPage