import React, { useEffect, useState } from 'react'
// import Testing from '../Testing';
import Video from '../Video'
import Titles from '../Titles'
import {BsCollectionFill} from 'react-icons/bs'
import axios from 'axios'
// import WatchPage from '../../Screens/WatchPage'

function PopularVideos() { 
    const [videobkp, setVideobkp] = useState([])
    useEffect(() => {
      axios

      // .get('http://192.168.137.1:3004/result')
      // .get('http://localhost:3004/result')
      .get('http://doodapi.com/api/file/list?key=215025x9vzozcxqqsoqvki')
      .then((response) => setVideobkp(response.data.files))
      .catch((error) => console.log(error))
    }, [])
  
return (
    <div className='my-16'>
      <Titles title='Free Videos' Icon={BsCollectionFill} />
      <div className='grid sm:mt-12 mt-6 xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid=cols-1 gap-10'>
        {
            videobkp.map((video,index) => (
                <Video key={index} video={video} />
                // <Video key={index} video={video} />
                // <WatchPage key={index} video={video} />

            ))
        }
      </div>
    </div>

  ); 
}

export default PopularVideos




// import React from 'react'
// import Titles from '../Titles'
// import {BsCollectionFill} from 'react-icons/bs'
// import Video from '../Video'
// import {Files} from '../../Data/VideosData'

// function PopularVideos() {
//   return (
//     <div className='my-16'>
//       <Titles title='Popular Videos' Icon={BsCollectionFill} />
//       <div className='grid sm:mt-12 mt-6 xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid=cols-1 gap-10'>
//         {
//           Files.slice(0,8).map((video,index) => (
//             <Video key={index} video={video}/>
//           ))
//         }
//       </div>
//     </div>
//   )
// }

// export default PopularVideos