import React from 'react'

function FlexVideoItems() {
  return (
    <div className='flex items-center gap-2'>
        {/* <span className='text-sm font-medium'>{video.category}</span> */}
    </div>
  )
}

export default FlexVideoItems