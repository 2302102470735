import React,{useState} from 'react'
import { Link, NavLink } from 'react-router-dom'
import {FaSearch} from 'react-icons/fa'
import {CgUser} from 'react-icons/cg'
// import {FaHeart} from 'react-icons/fa'

const baseUrl = 'https://doodapi.com/api/search/videos?key=215025x9vzozcxqqsoqvki';
// const baseUrl = 'http://192.168.137.1:3004/result';

function Navbar() {
    const hover = 'hover:text-subMain transitions text-white';
    const Hover = ({isActive}) => (isActive ? 'text-subMain' : hover)
    const [search, setSearch] = useState('');
    const [searchedVideos, setSearchedVideos] = useState([]);
    async function searchingVideos(){
        try {
            const response = await window.fetch(`${baseUrl}&search_term=${search}`);
            // ${search}`);
            const data = response.json();
            console.log('d',data)
            setSearchedVideos(data.result)
            console.log(searchedVideos)
        } catch (e) {
            console.log('Error Search ',e);
        }
    }

  return (
    <div className='bg-main shadow-md sticky top-0 z-20'>
        <div className='container bg-main mx-auto py-6 px-2 lg:grid gap-10 grid-cols-7 justify-between items-center'>
            {/* Logo */}
            <div className='col-span-1 lg:block hidden'>
                <Link to='/'>
                    <img src='/images/logo.png' alt='logo' className='w-full h-12 object-contain' />
                </Link>
            </div>
            {/* Search Form */}
            <div className='col-span-3'>
                <form className='w-full text-sm bg-dryGray rounded flex-btn gap-4'>
                    <input type='text' 
                        className='font-medium placeholder:text-border text-sm w-11/12 h-12 bg-transparent border-none px-2 text-black' 
                        placeholder='Search Video . . .' 
                        value={search} 
                        onChange={(e) => setSearch(e.target.value)}
                         />
                    
                    <button 
                        type='submit' 
                        className='bg-subMain w-12 flex-colo h-12 rounded text-white'
                        onClick={searchingVideos}    
                    >
                        <FaSearch />
                    </button>
                    
                    
                </form>
            </div>
            {/* Menus */}
            <div className='col-span-3 font-medium text-sm hidden xl:gap-14 2xl:gap-20 justify-between lg:flex xl:justify-end items-center'>
                <NavLink to='/' className={Hover}>
                    Home
                </NavLink>
                <NavLink to='/contact-us' className={Hover}>
                    Contact Us
                </NavLink>
                <NavLink to='/about-us' className={Hover}>
                    About Us
                </NavLink>
                <NavLink to='/login' className={Hover}>
                    <CgUser className='w-8 h-8'/>
                </NavLink>
                {/* This code above used for Favourites button */}
                {
                /* <NavLink to='/profle' className={`${Hover}`}>
                    <FaHeart className='w-6 h-6'/>
                    <div className='w-5 h-5 flex-colo rounded-full text-xs bg-subMain text-white -top-5 right-1'>
                        3
                    </div>
                </NavLink> */}
            </div>
        </div>
    </div>
  );
}

export default Navbar