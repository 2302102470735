import React from 'react'
import Layout from '../Layout/Layout'
import {useParams} from 'react-router-dom'
import {Files} from '../Data/VideosData';
import VideoInfo from '../Components/Single/VideoInfo'
import VideoCasts from '../Components/Single/VideoCasts';

function SingleVideo() {
    const {id} = useParams()
    const video = Files.find((video) => video.name === id)
  return (
    <Layout>
        <VideoInfo video={video} />
        <div className='conatiner mx-auto min-h-screen px-2 my-6'>
            <VideoCasts/>
        </div>
    </Layout>
  )
}

export default SingleVideo