export const Files = [
    {
        name:'Army of The Dead',
        desc:'Lorem Ipsum dolor sit amet parah yaelah elu mah gitu aja gajelas kontolllll',
        titleImage:'1a.jpg',
        image:'1.jpg',
        time:'250',
        video:'Army of the Dead - Official Teaser - Netflix.mp4',
    },
    {
        name:'Transformers',
        desc:'Lorem Ipsum dolor sit amet parah yaelah elu mah gitu aja gajelas kontolllll',
        titleImage:'2a.jpg',
        image:'2.jpg',
        time:'350',
        video:'',
    },
    {
        name:'Cheating Wife',
        desc:'Lorem Ipsum dolor sit amet parah yaelah elu mah gitu aja gajelas kontolllll',
        titleImage:'3a.jpg',
        image:'3.jpg',
        time:'250',
        video:'',
    },
    {
        name:'I dared my best friend to prove her blowjob skills',
        desc:'Lorem Ipsum dolor sit amet parah yaelah elu mah gitu aja gajelas kontolllll',
        titleImage:'4a.jpg',
        image:'4.jpg',
        time:'350',
        video:'',
    },
    {
        name:'Army of The Dead',
        desc:'Lorem Ipsum dolor sit amet parah yaelah elu mah gitu aja gajelas kontolllll',
        titleImage:'1a.jpg',
        image:'1.jpg',
        time:'250',
        video:'',
    },
    {
        name:'Transformers',
        desc:'Lorem Ipsum dolor sit amet parah yaelah elu mah gitu aja gajelas kontolllll',
        titleImage:'2a.jpg',
        image:'2.jpg',
        time:'350',
        video:'',
    },
    {
        name:'Cheating Wife',
        desc:'Lorem Ipsum dolor sit amet parah yaelah elu mah gitu aja gajelas kontolllll',
        titleImage:'3a.jpg',
        image:'3.jpg',
        time:'250',
        video:'',
    },
    {
        name:'I dared my best friend to prove her blowjob skills',
        desc:'Lorem Ipsum dolor sit amet parah yaelah elu mah gitu aja gajelas kontolllll',
        titleImage:'4a.jpg',
        image:'4.jpg',
        time:'350',
        video:'',
    },
    {
        name:'Army of The Dead',
        desc:'Lorem Ipsum dolor sit amet parah yaelah elu mah gitu aja gajelas kontolllll',
        titleImage:'1a.jpg',
        image:'1.jpg',
        time:'250',
        video:'',
    },
    {
        name:'Transformers',
        desc:'Lorem Ipsum dolor sit amet parah yaelah elu mah gitu aja gajelas kontolllll',
        titleImage:'2a.jpg',
        image:'2.jpg',
        time:'350',
        video:'',
    },
    {
        name:'Cheating Wife',
        desc:'Lorem Ipsum dolor sit amet parah yaelah elu mah gitu aja gajelas kontolllll',
        titleImage:'3a.jpg',
        image:'3.jpg',
        time:'250',
        video:'',
    },
    {
        name:'I dared my best friend to prove her blowjob skills',
        desc:'Lorem Ipsum dolor sit amet parah yaelah elu mah gitu aja gajelas kontolllll',
        titleImage:'4a.jpg',
        image:'4.jpg',
        time:'350',
        video:'',
    },
    {
        name:'Army of The Dead',
        desc:'Lorem Ipsum dolor sit amet parah yaelah elu mah gitu aja gajelas kontolllll',
        titleImage:'1a.jpg',
        image:'1.jpg',
        time:'250',
        video:'',
    },
    {
        name:'Transformers',
        desc:'Lorem Ipsum dolor sit amet parah yaelah elu mah gitu aja gajelas kontolllll',
        titleImage:'2a.jpg',
        image:'2.jpg',
        time:'350',
        video:'',
    },
    {
        name:'Cheating Wife',
        desc:'Lorem Ipsum dolor sit amet parah yaelah elu mah gitu aja gajelas kontolllll',
        titleImage:'3a.jpg',
        image:'3.jpg',
        time:'250',
        video:'',
    },
    {
        name:'I dared my best friend to prove her blowjob skills',
        desc:'Lorem Ipsum dolor sit amet parah yaelah elu mah gitu aja gajelas kontolllll',
        titleImage:'4a.jpg',
        image:'4.jpg',
        time:'350',
        video:'',
    },
    {
        name:'Army of The Dead',
        desc:'Lorem Ipsum dolor sit amet parah yaelah elu mah gitu aja gajelas kontolllll',
        titleImage:'1a.jpg',
        image:'1.jpg',
        time:'250',
        video:'',
    },
    {
        name:'Transformers',
        desc:'Lorem Ipsum dolor sit amet parah yaelah elu mah gitu aja gajelas kontolllll',
        titleImage:'2a.jpg',
        image:'2.jpg',
        time:'350',
        video:'',
    },
    {
        name:'Cheating Wife',
        desc:'Lorem Ipsum dolor sit amet parah yaelah elu mah gitu aja gajelas kontolllll',
        titleImage:'3a.jpg',
        image:'3.jpg',
        time:'250',
        video:'',
    },
    {
        name:'I dared my best friend to prove her blowjob skills',
        desc:'Lorem Ipsum dolor sit amet parah yaelah elu mah gitu aja gajelas kontolllll',
        titleImage:'4a.jpg',
        image:'4.jpg',
        time:'350',
        video:'',
    },
]