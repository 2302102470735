import React from 'react';
import Layout from './../Layout/Layout';
import Head from '../Components/Head';

// if need more about this page. check yt on link below, at 2:00:00
// https://www.youtube.com/watch?v=K_L90oBB0qM&ab_channel=Zpunet

function AboutUs() {
  return (
    <Layout>
      <div className='min-height-screen contain mx-auto px-2 my-6'>
        <Head title='Contact Us' />
        <div className='xl:py-20 py-10 px-4'>
          <div className='grid grid-flow-row xl:grid-cols-2 gap-4 xl:gap-16 items-center'>
            <div>
              {/* <h1  className='text-xl lg:text-3xl mb-4 font-bold'>
                About Us
              </h1> */}
              <h3 className='text-xl lg:text-3xl mb-4 font-semibold'>
                You can contact to our email
              </h3>
              <p>
                All of this videos are free view for you. 
              </p>
            </div>
            <div className='mt-3 text-sm leading-8  text-text'>
              
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutUs