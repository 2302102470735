import React from 'react';
import Layout from '../Layout/Layout';
// import Banner from '../Components/Home/Banner';
import PopularVideos from '../Components/Home/PopularVideos';
// import TopView from '../Components/Home/TopView';
// import TestingPopular from '../Components/Home/TestingPopular';


function HomeScreen() {
  return (
    <Layout>
      <div className='container mx-auto min-h-screen px-2 mb-6'>
        {/* <Banner /> */}
        <PopularVideos />
        {/* <PopularVideos /> */}
        {/* <TopView /> */}
      </div>
    </Layout>
  );
}

export default HomeScreen 