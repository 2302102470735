import React from 'react'
// import { CategoriesData } from './../../Data/CategoriesData'

// const YearData = [
//   {
//     {title:"Sort by Year"},
//     {title:"2000-2010"},
//     {title:"2011-2020"},
//     {title:"2021-2030"},
//   }
// ]

// const Filter = [
//   {
//     value:category,
//     onChange:setCategory,
//     items:CategoriesData,
//   },
//   {
//     value:YearData,
//     onChange:setYear,
//     items:YearData
//   }
// ]

// THIS FUNCTION IS UNFINISHED / NOT WORKING
// if need this Filter Function, see youtube link below ( at 2:00:10 )
// https://www.youtube.com/watch?v=K_L90oBB0qM&t=7910s

function Filters() {
  return (
    <div className='my-6 bg-dry border text-dryGray border-gray-800 grid md:grid-cols-4 grid-cols-2 lg:gap-12 gap-2 rounded p-6'>

    </div>
  )
}

export default Filters